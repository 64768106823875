.calenderTextField .MuiInput-underline::before,
.calenderTextField .MuiInput-underline:hover::before,
.calenderTextField .MuiInput-underline::after,
.calenderTextField .MuiInput-underline:hover::after {
    border-bottom: none !important;
}

.calenderTextField:before {
    content: attr(placeholder) !important;
    color: #aaa;
    margin-right: 0.1em;
    width: 100%;
  }
 .calenderTextField:focus:before,
 .calenderTextField:valid:before {
    content: "";
  }

  .calenderTextField input::placeholder {
    visibility: visible;
  }
  
  .calenderTextField input:not(:focus):before {
    content: attr(placeholder);
    color: #aaa;
    margin-right: 0.1em;
    width: 100%;
  }
  
  .calenderTextField input:focus:before,
  .calenderTextField input:valid:before {
    content: "";
  }

  .calenderTextField .MuiFormHelperText-root.Mui-error {
    display: none;
  }

  .calenderTextField .MuiInputAdornment-positionEnd {
    margin-left: -50px;
    /* margin-top: -10px; */
  }

  /* @media screen and (max-width: 1440px) {
    .calenderTextField .MuiInputAdornment-positionEnd {
      margin-left: -50px;
    }
  } */

  .calenderTextField .MuiIconButton-root {
    margin-top: 0px;
  }

  .calenderTextField .MuiFormControl-marginNormal {
    margin-top: 0px !important;
  }

  /* input::placeholder {
    color: rgba(0, 0, 0, 0.42);
    opacity: 1; 
} */
  
  .calenderTextField .MuiPickersModal-dialogRoot {
    min-width: 310px !important;
    position: absolute !important;
    top: 50px !important;
    left: auto !important;
    right: 50px !important;
    transform: none !important;
  }

  .MuiPickersToolbar-toolbar {
    background-color: #000fff !important;
  }

  .MuiButton-textPrimary  {
    color: #000fff !important;
  }

  .MuiPickersYear-yearSelected {
    color: #000fff !important;
  }

  .MuiPickersDay-daySelected {
    background-color: #000fff !important;
  }

